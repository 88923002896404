module.exports = [
  {
    path: '/pcx/index/:pagetime?',
    pattern: /^\/pcx\/index(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/index.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/index.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/functree/:pagetime?',
    pattern: /^\/pcx\/entConfig\/functree(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/functree.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/functree.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/main/dialog/newLayoutSave/:pagetime?',
    pattern: /^\/pcx\/main\/dialog\/newLayoutSave(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/main/dialog/newLayoutSave.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/main/dialog/newLayoutSave.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/infoPublish/infoView/:pagetime?',
    pattern: /^\/pcx\/infoPublish\/infoView(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/infoPublish/infoView.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/infoPublish/infoView.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antdpro/components/ExportExcel/dialog/setting/:pagetime?',
    pattern: /^\/comp\/antdpro\/components\/ExportExcel\/dialog\/setting(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antdpro/components/ExportExcel/dialog/setting.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antdpro/components/ExportExcel/dialog/setting.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/selectOuterDatabase/:pagetime?',
    pattern: /^\/pcx\/entConfig\/selectOuterDatabase(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/selectOuterDatabase.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/selectOuterDatabase.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antdpro/components/OrgDialog/dialog/singleOrgSelect/:pagetime?',
    pattern: /^\/comp\/antdpro\/components\/OrgDialog\/dialog\/singleOrgSelect(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antdpro/components/OrgDialog/dialog/singleOrgSelect.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antdpro/components/OrgDialog/dialog/singleOrgSelect.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/antdDialog/batchTask/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/antdDialog\/batchTask(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/antdDialog/batchTask.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/antdDialog/batchTask.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/infoPublish/infoDetail/:pagetime?',
    pattern: /^\/pcx\/infoPublish\/infoDetail(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/infoPublish/infoDetail.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/infoPublish/infoDetail.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/authorize/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/authorize(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/authorize.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/authorize.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/functreeHistory/:pagetime?',
    pattern: /^\/pcx\/entConfig\/functreeHistory(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/functreeHistory.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/functreeHistory.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/changePassword/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/changePassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/changePassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/changePassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/main/main/:pagetime?',
    pattern: /^\/pcx\/main\/main(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/main/main.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/main/main.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antdpro/components/RestdataFilterDialog/dialog/query/:pagetime?',
    pattern: /^\/comp\/antdpro\/components\/RestdataFilterDialog\/dialog\/query(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antdpro/components/RestdataFilterDialog/dialog/query.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antdpro/components/RestdataFilterDialog/dialog/query.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgSetValuePolicy/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgSetValuePolicy(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgSetValuePolicy.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgSetValuePolicy.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antd/components/RestdataFilterDialog/dlg/query/:pagetime?',
    pattern: /^\/comp\/antd\/components\/RestdataFilterDialog\/dlg\/query(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antd/components/RestdataFilterDialog/dlg/query.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antd/components/RestdataFilterDialog/dlg/query.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/infoPublish/infoColumn/:pagetime?',
    pattern: /^\/pcx\/infoPublish\/infoColumn(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/infoPublish/infoColumn.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/infoPublish/infoColumn.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/main/widget/widgetConfig/:pagetime?',
    pattern: /^\/pcx\/main\/widget\/widgetConfig(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/main/widget/widgetConfig.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/main/widget/widgetConfig.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/antdDialog/chart/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/antdDialog\/chart(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/antdDialog/chart.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/antdDialog/chart.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/PermSeek/:pagetime?',
    pattern: /^\/pcx\/entConfig\/PermSeek(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/PermSeek.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/PermSeek.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/dialog/favorites/:pagetime?',
    pattern: /^\/pcx\/dialog\/favorites(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/dialog/favorites.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/dialog/favorites.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/register/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/register(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/register.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/register.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antd/components/UserDialog/org/userDialog/:pagetime?',
    pattern: /^\/comp\/antd\/components\/UserDialog\/org\/userDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antd/components/UserDialog/org/userDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antd/components/UserDialog/org/userDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/entorgsm/components/orgSelect/dialog/orgDialog/:pagetime?',
    pattern: /^\/comp\/entorgsm\/components\/orgSelect\/dialog\/orgDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/entorgsm/components/orgSelect/dialog/orgDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/entorgsm/components/orgSelect/dialog/orgDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/login/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/login(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/login.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/login.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgPolicy/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgPolicy(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgPolicy.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgPolicy.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgValidationFunc/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgValidationFunc(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgValidationFunc.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgValidationFunc.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/pcxDialog/remind/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/pcxDialog\/remind(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/pcxDialog/remind.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/pcxDialog/remind.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/functreeSelect/:pagetime?',
    pattern: /^\/pcx\/entConfig\/functreeSelect(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/functreeSelect.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/functreeSelect.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/pcxDialog/dialog/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/pcxDialog\/dialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/pcxDialog/dialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/pcxDialog/dialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/user/login/:pagetime?',
    pattern: /^\/pcx\/user\/login(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/user/login.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/user/login.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgProcs/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgProcs(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgProcs.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgProcs.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/appRoleMgmt/:pagetime?',
    pattern: /^\/pcx\/entConfig\/appRoleMgmt(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/appRoleMgmt.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/appRoleMgmt.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/main/main-design/:pagetime?',
    pattern: /^\/pcx\/main\/main-design(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/main/main-design.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/main/main-design.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/pcxDialog/batchTask/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/pcxDialog\/batchTask(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/pcxDialog/batchTask.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/pcxDialog/batchTask.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/user/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/user(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/user.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/user.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/pcxDialog/base/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/pcxDialog\/base(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/pcxDialog/base.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/pcxDialog/base.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/antdDialog/base/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/antdDialog\/base(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/antdDialog/base.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/antdDialog/base.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgTables/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgTables(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgTables.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgTables.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/dialog/concat/:pagetime?',
    pattern: /^\/pcx\/dialog\/concat(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/dialog/concat.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/dialog/concat.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/antdDialog/dialog/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/antdDialog\/dialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/antdDialog/dialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/antdDialog/dialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgProcsAuth/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgProcsAuth(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgProcsAuth.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgProcsAuth.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/user/changePassword/:pagetime?',
    pattern: /^\/pcx\/user\/changePassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/user/changePassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/user/changePassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/editSqlPermission/:pagetime?',
    pattern: /^\/pcx\/entConfig\/editSqlPermission(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/editSqlPermission.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/editSqlPermission.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/pcxDialog/record/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/pcxDialog\/record(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/pcxDialog/record.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/pcxDialog/record.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wxxcx_login/components/wxxcx_login/dialog/getPhoneNumber/:pagetime?',
    pattern: /^\/comp\/wxxcx_login\/components\/wxxcx_login\/dialog\/getPhoneNumber(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wxxcx_login/components/wxxcx_login/dialog/getPhoneNumber.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wxxcx_login/components/wxxcx_login/dialog/getPhoneNumber.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/pcxDialog/chart/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/pcxDialog\/chart(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/pcxDialog/chart.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/pcxDialog/chart.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/wfmui/components/wfmui/antdDialog/record/:pagetime?',
    pattern: /^\/comp\/wfmui\/components\/wfmui\/antdDialog\/record(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/wfmui/components/wfmui/antdDialog/record.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/wfmui/components/wfmui/antdDialog/record.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/infoPublish/infoPub/:pagetime?',
    pattern: /^\/pcx\/infoPublish\/infoPub(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/infoPublish/infoPub.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/infoPublish/infoPub.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/dlgDataPermission/:pagetime?',
    pattern: /^\/pcx\/entConfig\/dlgDataPermission(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/dlgDataPermission.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/dlgDataPermission.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/infoPublish/infoList/:pagetime?',
    pattern: /^\/pcx\/infoPublish\/infoList(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/infoPublish/infoList.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/infoPublish/infoList.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/bindphone/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/bindphone(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/bindphone.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/bindphone.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/infoPublish/infoBoard/:pagetime?',
    pattern: /^\/pcx\/infoPublish\/infoBoard(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/infoPublish/infoBoard.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/infoPublish/infoBoard.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antd/components/OrgDialog/org/singleOrgSelect/:pagetime?',
    pattern: /^\/comp\/antd\/components\/OrgDialog\/org\/singleOrgSelect(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antd/components/OrgDialog/org/singleOrgSelect.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antd/components/OrgDialog/org/singleOrgSelect.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antdpro/components/OrgDialog/dialog/multipleOrgSelect/:pagetime?',
    pattern: /^\/comp\/antdpro\/components\/OrgDialog\/dialog\/multipleOrgSelect(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antdpro/components/OrgDialog/dialog/multipleOrgSelect.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antdpro/components/OrgDialog/dialog/multipleOrgSelect.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/iconSelect/:pagetime?',
    pattern: /^\/pcx\/entConfig\/iconSelect(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/iconSelect.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/iconSelect.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/twofactorlogin/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/twofactorlogin(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/twofactorlogin.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/twofactorlogin.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/wxsys/comps/user/userDialog/forgetPassword/:pagetime?',
    pattern: /^\/wxsys\/comps\/user\/userDialog\/forgetPassword(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/wxsys/comps/user/userDialog/forgetPassword.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/wxsys/comps/user/userDialog/forgetPassword.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/entConfig/editAppRole/:pagetime?',
    pattern: /^\/pcx\/entConfig\/editAppRole(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/entConfig/editAppRole.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/entConfig/editAppRole.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/pcx/user/profile/:pagetime?',
    pattern: /^\/pcx\/user\/profile(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/pcx/user/profile.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/pcx/user/profile.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antdpro/components/UserDialog/dialog/userDialog/:pagetime?',
    pattern: /^\/comp\/antdpro\/components\/UserDialog\/dialog\/userDialog(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antdpro/components/UserDialog/dialog/userDialog.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antdpro/components/UserDialog/dialog/userDialog.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
  {
    path: '/comp/antd/components/OrgDialog/org/mulOrgSelect/:pagetime?',
    pattern: /^\/comp\/antd\/components\/OrgDialog\/org\/mulOrgSelect(?:\/([^\/#\?]+?))?[\/#\?]?$/i,
    keys: [{"name":"pagetime","prefix":"/","suffix":"","pattern":"[^\\/#\\?]+?","modifier":"?"}],
    page: 'pages/comp/antd/components/OrgDialog/org/mulOrgSelect.component.js',
    load: async function() {

        /**
        let PageComponent = await import('pages/comp/antd/components/OrgDialog/org/mulOrgSelect.component.js');
        return PageComponent.default;
        **/
        return Promise.reject('uix统一走页面单独编译方案,不应该走到本地load的方式');
    },
  },
];